<template>
  <div class="header">
    <img class="header-blur-bg" :src="albumInfo.cover" alt="cover" />
    <div class="header-content">
      <div class="header-content__undercover"></div>
      <img :src="albumInfo.cover" alt="" class="header-content__cover" />
      <div class="header-content__playcount">
        <img
          src="https://static1.kaixinyf.cn/img/lza6140155d4c227385776157.png"
          class="header-content__playcount__icon"
          alt="playcount"
        />
        <span class="header-content__playcount__count"> {{ tranNumber(albumInfo.play_times, 2) }} </span>
      </div>
      <h3>{{ albumInfo.title }}</h3>
      <div class="header-content__user">
        <img v-if="albumInfo.author_avatar" :src="albumInfo.author_avatar" alt="头像" />
        <span>{{ albumInfo.author_name }}</span>
      </div>
      <div class="header-content__desc" v-if="albumInfo.desc && SOURECE_TYPE != 2">简介：{{ albumInfo.desc }}</div>
      <div v-if="SOURECE_TYPE == 2" class="header-content__btn" @click="download('我也要创作')">
        <img class="header-content__btn__icon" src="https://static1.kaixinyf.cn/img/lza6520f67911c1d014685114.png" />
        我也要创作
      </div>
    </div>
  </div>
</template>

<script>
  import { tranNumber } from '@/utils/common';

  export default {
    name: 'index.vue',
    components: {},
    props: {
      albumInfo: {
        type: Object,
        default: () => {},
      },
      download: {
        type: Function,
      },
      SOURECE_TYPE: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      tranNumber: tranNumber,
    },
  };
</script>

<style lang="less" scoped>
  .header {
    background-size: cover;
    z-index: -1;
    &-blur-bg {
      position: absolute;
      height: 4.2rem;
      -webkit-filter: blur(20px); /* Chrome, Opera */
      -moz-filter: blur(20px);
      -ms-filter: blur(20px);
      filter: blur(20px);
      z-index: -1;
      background: rgba(0, 0, 0, 0.2);
    }
    &-content {
      position: relative;
      &__cover {
        position: absolute;
        top: 0.32rem;
        left: 0.3rem;
        width: 2.28rem;
        height: 2.28rem;
        border-radius: 0.16rem;
      }
      &__playcount {
        position: absolute;
        top: 2.21rem;
        left: 0.42rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__icon {
          width: 0.24rem;
          height: 0.24rem;
        }
        &__count {
          margin-left: 6px;
          height: 0.3rem;
          line-height: 0.3rem;
          font-size: 0.2rem;
          color: #ffffff;
        }
      }
      &__undercover {
        position: absolute;
        top: 0.44rem;
        left: 0.66rem;
        width: 2.04rem;
        height: 2.04rem;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 0.16rem;
      }
      h3 {
        //position: absolute;
        margin-top: 0.68rem;
        margin-left: 3.02rem;
        float: left;
        width: 4.18rem;
        //height: 1.12rem;
        font-size: 0.4rem;
        font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
        font-weight: 600;
        color: #ffffff;
        text-shadow: 0 0 0 rgba(0, 0, 0, 0.12);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &__user {
        float: left;
        //position: absolute;
        margin-top: 0.12rem;
        margin-left: 3.02rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 0.32rem;
          height: 0.32rem;
          border-radius: 50%;
        }
        span {
          margin-left: 6px;
          height: 0.32rem;
          line-height: 0.32rem;
          font-size: 0.24rem;
          color: #ffffff;
        }
      }
      &__desc {
        position: absolute;
        top: 2.89rem;
        left: 0.3rem;
        width: 6.9rem;
        height: 0.64rem;
        font-size: 0.24rem;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &__btn {
        position: absolute;
        top: 2.89rem;
        left: 0.3rem;
        width: 6.9rem;

        height: 0.64rem;
        background: rgba(247, 248, 250, 0.18);
        border-radius: 0.32rem;
        font-size: 0.26rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 0.64rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &__icon {
          width: 0.36rem;
          height: 0.36rem;
          margin-right: 0.16rem;
        }
      }
    }
  }
</style>
