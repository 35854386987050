<template>
  <div @click.self="changeDownloadModal(false)" class="download-modal">
    <div class="download-modal-container">
      <div class="download-modal-info">
        {{ SHARE_PLAYLIST_AB === 'b' ? '来快音免费听歌还能赚钱' : '当前歌曲试听已结束' }}
      </div>
      <img class="download-modal-img" src="https://static1.kaixinyf.cn/img/lza62a85af18d2f9448964365.png" alt="" />
      <button
        @click="download(SHARE_PLAYLIST_AB === 'b' ? '引导弹窗-果断下载' : '引导弹窗-下载继续听歌')"
        class="download-modal-btn"
      >
        {{ SHARE_PLAYLIST_AB === 'b' ? '果断下载' : '下载继续听歌' }}
      </button>
      <div class="download-modal-tips">
        <span>听歌即可赚钱</span>
        <span>新用户注册最高可领100元现金红包</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'index',
    props: {
      download: {
        type: Function,
        default: () => {},
      },
      changeDownloadModal: {
        type: Function,
        default: () => {},
      },
    },
    mounted() {
      document.body.style.overflow = 'hidden';
    },
    computed: {
      ...mapGetters('activity/album', ['SHARE_PLAYLIST_AB']),
    },
    beforeDestroy() {
      document.body.style.overflow = '';
    },
  };
</script>

<style scoped lang="less">
  .download-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.75);
    &-container {
      background: #fff;
      border-radius: 0.12rem;
      width: 6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.3rem;
      box-sizing: border-box;
    }
    &-info {
      font-size: 0.36rem;
      font-weight: bold;
      color: #c02929;
    }
    &-img {
      width: 3.8rem;
      height: 1.64rem;
      margin: 0.2rem 0;
    }
    &-btn {
      height: 0.8rem;
      border-radius: 0.4rem;
      background: #fa3123;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.32rem;
      font-weight: bold;
      color: #fff;
      width: 5.2rem;
      border: none;
      margin: 0.2rem 0;
      &:active {
        transform: scale(0.95);
      }
    }
    &-tips {
      font-size: 0.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #a6a6a6;
    }
  }
</style>
