<template>
  <div class="info">
    <div class="info-top">
      <h3 v-if="!music.isPlaying">{{ music.title }}</h3>
      <van-notice-bar class="notice-swipe" color="#1a1a1a" v-else scrollable :text="music.title" />
    </div>
    <div class="info-bottom">
      <div class="info-bottom__tag" v-if="music.tags.length === 0">MV</div>
      <div class="info-bottom__name">{{ music.name }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Info',
    components: {},
    props: {
      music: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style lang="less" scoped>
  .info {
    width: 4.66rem;
    height: 0.71rem;
    padding: 0.3rem 0;
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #1a1a1a;
      font-size: 0.28rem;
    }
    &-bottom {
      display: flex;
      align-items: center;
      &__tag {
        margin-right: 0.12rem;
        padding: 0.04rem 0.08rem;
        font-size: 0.2rem;
        font-weight: 600;
        color: #a6a6a6;
        background: #f5f5f5;
        border-radius: 0.04rem;
      }
      &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 2.13rem;
        font-size: 0.24rem;
        color: #a6a6a6;
        letter-spacing: 1px;
      }
    }
  }
  .notice-swipe {
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 4.66rem;
    height: 0.28rem;
    color: #1a1a1a;
    font-size: 0.28rem;
    font-weight: bold;
    background-color: #ffffff;
  }
</style>
