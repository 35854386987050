<template>
  <li class="music" @click="handleMusic">
    <Cover :music="musicInfo" :current-time="currentTime" class="music-cover" />
    <Info :music="musicInfo" class="music-info" />
    <PlayIcon :music="musicInfo" class="music-icon" />
  </li>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import Cover from './Cover';
  import Info from './Info';
  import PlayIcon from './PlayIcon';
  export default {
    name: 'ListItem.vue',
    components: {
      Cover,
      Info,
      PlayIcon,
    },
    props: {
      musicInfo: {
        type: Object,
        default: () => null,
      },
      currentTime: {
        type: Number,
      },
    },
    data() {
      return {};
    },
    computed: {
      ...mapGetters('activity/album', ['PLAYING_MUSIC']),
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
      ...mapMutations('activity/album', ['SET_PLAYING_MUSIC']),
      /**
       * @description: 处理音乐播放
       */

      handleMusic() {
        this.$emit('handleMusic', this.musicInfo.isPlaying, this.musicInfo);
      },
    },
  };
</script>

<style lang="less" scoped>
  .music {
    display: flex;
    align-items: center;
    height: 1.31rem;
    border-bottom: 1px solid #e0e0e0;
    &-cover {
      margin-right: 0.24rem;
    }
    &-icon {
      margin-left: 0.4rem;
    }
  }
</style>
