<template>
  <div class="album">
    <template v-if="ALBUM.is_private === 0">
      <HeaderAi
        v-if="+ALBUM.source_type == 2"
        class="album__head"
        :album-info="ALBUM"
        :download="handleDownload"
        :SOURECE_TYPE="+ALBUM.source_type"
      />
      <Header
        v-else
        class="album__head"
        :album-info="ALBUM"
        :download="handleDownload"
        :SOURECE_TYPE="+ALBUM.source_type"
      />
      <List class="album__list" :style="{ marginTop: marginTop }" :list="MUSIC_LIST" :download="handleDownload" />
    </template>
    <template v-else>
      <WithoutData text="此歌单已被创建者设为隐私" :img-src="img" />
    </template>
    <Bottom class="album__bottom" :download="handleDownload" />
    <DownloadModal
      :changeDownloadModal="changeDownloadModal"
      :download="handleDownload"
      v-if="isDownloadModalShow"
    ></DownloadModal>
  </div>
</template>

<script>
  import Header from './components/Header';
  import HeaderAi from './components/Header/index_ai';
  import List from './components/List';
  import Bottom from './components/Bottom';
  import DownloadModal from './components/DownloadModal';
  import API from '@/api';
  import { mapGetters } from 'vuex';
  import Download from '@/utils/download';

  export default {
    name: 'Album',
    components: { Header, List, Bottom, DownloadModal, HeaderAi },
    props: {},
    async asyncData({ store, $http, params }) {
      const { playlist_info, music_list, share_playlist_ab } = await $http.post(API.GET_ALBUM_PLAY_LIST, {
        ...params,
        last_id: 0,
      });
      store.commit('activity/album/SET_ALBUM_INFO', playlist_info);
      store.commit('activity/album/SET_MUSIC_LIST', music_list);
      store.commit('activity/album/SET_SHARE_PLAYLIST_AB', share_playlist_ab);
    },
    data() {
      return {
        img: require('@/assets/images/common/private_icon.png'),
        isDownloadModalShow: false,
        downloadModalShowTimeout: null,
        trackClick: null,
      };
    },
    computed: {
      ...mapGetters('activity/album', ['ALBUM', 'MUSIC_LIST', 'SHARE_PLAYLIST_AB', 'SOURECE_TYPE']),
      appName() {
        return this.$store.getters.client['app-name'];
      },
      marginTop() {
        return this.ALBUM.desc ? '3.8rem' : '2.92rem';
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.trackClick = this.track();
      this.trackClick('曝光');
      document.title = this.ALBUM.title || '【快音】- 歌单';
      const sensorsData = JSON.parse(this.getCookie('sensorsdata2015jssdkcross'));
      this.$http.TaskPost('/ShareMusicReward/Report', {
        music_code: this.$route.query.playlist_id,
        share_from: this.$route.query.share_from,
        distinct_id: sensorsData.distinct_id,
        business: 'playlist',
      });
      this.downloadModalShowTimeout = setTimeout(
        () => {
          this.changeDownloadModal(true);
          this.trackClick('引导弹窗-曝光');
          clearTimeout(this.downloadModalShowTimeout);
        },
        this.SHARE_PLAYLIST_AB === 'b' ? 5000 : 20000
      );
    },
    methods: {
      track() {
        return (element_name) => {
          let trackData = {
            page_title: +this.ALBUM.source_type == 2 ? 'ai歌单分享落地页' : '歌单分享页',
            element_name,
          };
          if (+this.ALBUM.source_type == 2) trackData.remarks = this.$route.query.playlist_id;
          this.$track({ ...trackData });
        };
      },
      getCookie(name) {
        let arr,
          reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
        if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
        else return null;
      },
      changeDownloadModal(flag) {
        if (!flag) this.trackClick('引导弹窗-关闭');
        this.isDownloadModalShow = flag;
      },
      /**
       * @description: 跳转商店或者歌单页
       */
      handleDownload(element_name) {
        // 如果是ai歌单
        element_name && this.trackClick(element_name);
        let url = '';

        if (this.$platform.Android && this.$platform.WeiXin) {
          url = Download.downLoad('yyb');
          window.location.href = url;
        } else if (this.$platform.Android && !this.$platform.WeiXin) {
          // window.open(
          //   `kuaiyin://playlist?playlist_id=${this.$route.query.playlist_id}&playlist_type=${this.$route.query.type}`,
          //   '_blank'
          // );
          // setTimeout(() => {
          //   url = Download.downLoad('yyb');
          //   window.location.href = url;
          // }, 3000);
          url = Download.downLoad('yyb');
          window.location.href = url;
        } else if (this.$platform.iOS) {
          // url = Download.downLoad('share_ios');
          // window.location.href = `https://740000d.umindex.com/playlist?playlist_id="${playlist_id}"&playlist_type=${type}`;
          window.location.href = `https://740000d.umindex.com/home`;
          return;
        } else {
          url = Download.downLoad('yyb');
          window.location.href = url;
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .album {
    &__head {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }
    &__list {
      margin-top: 3.2rem;
    }
    &__bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
</style>
