<template>
  <div class="cover">
    <img v-if="music.cover" :src="music.cover" alt="cover" />
    <div v-if="music.isPlaying" class="cover--activate">{{ currentTime | formatMusicTime }}</div>
  </div>
</template>

<script>
  import { formatTime } from '@/utils/common';

  export default {
    name: 'Cover.vue',
    components: {},
    props: {
      music: {
        type: Object,
        default: () => {},
      },
      currentTime: {
        type: Number,
      },
    },
    data() {
      return {};
    },
    filters: {
      formatMusicTime(time) {
        if (!time) time = 0;
        return formatTime(time);
      },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style lang="less" scoped>
  .cover {
    position: relative;
    img {
      width: 0.96rem;
      height: 0.96rem;
      border-radius: 0.2rem;
      z-index: -1;
    }
    &--activate {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.96rem;
      height: 0.96rem;
      line-height: 0.96rem;
      border-radius: 0.2rem;
      color: #ffffff;
      font-size: 0.28rem;
      text-align: center;
      background: rgba(0, 0, 0, 0.8);
    }
  }
</style>
