<template>
  <div class="icon">
    <img :src="imgUrl" alt="icon" />
  </div>
</template>

<script>
  export default {
    name: 'PlayIcon',
    components: {},
    props: {
      music: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        playIcon: 'https://static1.kaixinyf.cn//img/lza614402c0d7e7a231349637.png',
        pauseIcon: 'https://static1.kaixinyf.cn//img/lza614402cfb6b60735895991.png',
      };
    },
    computed: {
      imgUrl() {
        return this.music.isPlaying ? this.pauseIcon : this.playIcon;
      },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style lang="less" scoped>
  .icon {
    img {
      width: 0.64rem;
      height: 0.64rem;
    }
  }
</style>
