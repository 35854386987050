<template>
  <div class="bottom">
    <button @click="download(btnFont)">{{ btnFont }}</button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'index',
    components: {},
    props: {
      download: {
        type: Function,
      },
    },
    data() {
      return {};
    },
    computed: {
      ...mapGetters('activity/album', ['SHARE_PLAYLIST_AB']),
      btnFont() {
        return this.SHARE_PLAYLIST_AB === 'b' ? '去快音最高领100元新人红包' : '去快音听更多歌曲';
      },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style lang="less" scoped>
  .bottom {
    display: flex;
    width: 100%;
    height: 1.56rem;
    justify-content: center;
    align-items: center;
    button {
      width: 6.9rem;
      height: 0.92rem;
      font-size: 0.32rem;
      font-weight: 600;
      color: #ffffff;
      background: #fa3123;
      border-radius: 0.46rem;
      border: none;
      text-align: center;
    }
  }
</style>
