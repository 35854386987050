<template>
  <div class="list">
    <header>
      <h3>共{{ list.length }}首</h3>
      <button @click="handleAllMusic" v-if="list.length">
        <img :src="buttonIcon" alt="playcount" />
        <span>{{ buttonText }}</span>
      </button>
    </header>
    <MusicItem
      v-for="(music, index) in list"
      :key="index"
      :music-info="music"
      @handleMusic="handleClickMusicItem"
      :current-time="currentTime"
    />
    <button class="list-listen-all" v-show="list.length >= 30" @click="download('去快音听全部歌曲')">
      去快音听全部歌曲
    </button>
  </div>
</template>

<script>
  import MusicItem from './MusicItem';
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'index.vue',
    components: { MusicItem },
    props: {
      list: {
        type: Array,
        default: () => [],
      },
      download: {
        type: Function,
      },
    },
    data() {
      return {
        loading: false,
        finished: false,
        currentTime: 0,
        playIcon: 'https://static1.kaixinyf.cn/img/lza6140155d4c227385776157.png',
        pauseIcon: 'https://static1.kaixinyf.cn//img/lza61440da254ff7591555509.png',
        audio: null,
      };
    },
    computed: {
      ...mapGetters('activity/album', ['PLAYING_MUSIC']),
      buttonText() {
        if (this.PLAYING_MUSIC) {
          return this.PLAYING_MUSIC.isPlaying ? '暂停播放' : '播放全部';
        }
        return '播放全部';
      },
      buttonIcon() {
        if (this.PLAYING_MUSIC) {
          return this.PLAYING_MUSIC.isPlaying ? this.pauseIcon : this.playIcon;
        }
        return this.playIcon;
      },
      music() {
        return this.PLAYING_MUSIC || { play_url: '' };
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.initAudio();
    },
    methods: {
      ...mapMutations('activity/album', ['SET_PLAYING_MUSIC', 'SET_MUSIC_LIST']),
      /**
       * @description: 初始化 Audio 对象
       */

      initAudio() {
        this.audio = new Audio();
        this.audio.type = 'audio/mp3';
        this.audio.addEventListener('timeupdate', this.handleTimeUpdate);
        this.audio.addEventListener('ended', this.handleMusicEnd);
        this.audio.addEventListener('error', this.handleMusicError);
      },
      /**
       * @description: 播放/暂停全部音乐
       */

      handleAllMusic() {
        if (!this.PLAYING_MUSIC) {
          this.$track({
            page_title: '歌单分享页',
            element_name: '播放全部',
          });
          this.playMusic(this.list[0]);
        } else {
          if (!this.PLAYING_MUSIC.isPlaying) {
            this.$track({
              page_title: '歌单分享页',
              element_name: '播放全部',
            });
            this.audio
              .play()
              .then(() => {
                this.PLAYING_MUSIC.isPlaying = true;
              })
              .catch(() => {
                this.PLAYING_MUSIC.isPlaying = false;
              });
          } else {
            this.PLAYING_MUSIC.isPlaying = false;
            this.audio.pause();
          }
        }
      },
      /**
       * @description: 处理音乐播放结束
       */

      handleMusicEnd() {
        if (this.PLAYING_MUSIC) {
          const nextMusicIndex = this.PLAYING_MUSIC.index + 1 !== this.list.length ? this.PLAYING_MUSIC.index + 1 : 0;

          this.playMusic(this.list[nextMusicIndex]);
        }
      },
      /**
       * @description: 处理音乐播放出错
       */

      handleMusicError() {
        this.$toast('音频走丢了');
        this.handleMusicEnd();
      },
      /**
       * @description: 处理音乐播放时间更新
       */

      handleTimeUpdate(e) {
        this.currentTime = e.target.duration - e.target.currentTime;
      },
      /**
       * @description: 处理点击音乐 Item
       */

      handleClickMusicItem(isPlaying, music) {
        if (!isPlaying) {
          this.playMusic(music);
          this.$track({
            page_title: '歌单分享页',
            element_name: '播放单曲',
          });
        } else {
          music.isPlaying = false;
          this.audio.pause();
        }

        // window.location.href = music.share_info.url;
      },
      /**
       * @description: 播放音乐
       */

      playMusic(music) {
        this.SET_MUSIC_LIST(this.list);
        this.SET_PLAYING_MUSIC(music);
        this.audio.src = this.PLAYING_MUSIC.play_url;
        this.audio.load();
        this.audio
          .play()
          .then(() => {
            music.isPlaying = true;
          })
          .catch(() => {
            music.isPlaying = false;
          });
      },
    },
  };
</script>

<style lang="less" scoped>
  .list {
    padding: 0 0.3rem 1.56rem;
    border-top-left-radius: 0.24rem;
    border-top-right-radius: 0.24rem;
    background-color: #ffffff;
    z-index: -2;
    min-height: 4.2rem;
    header {
      height: 1.04rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: 0.32rem;
      }
      button {
        padding-left: 0.24rem;
        display: flex;
        align-items: center;
        width: 1.8rem;
        height: 0.56rem;
        line-height: 0.56rem;
        background: #fa3123;
        border-radius: 0.28rem;
        border: none;
        color: #ffffff;
        img {
          margin-right: 0.06rem;
          width: 0.28rem;
          height: 0.28rem;
        }
      }
      span {
        font-size: 0.26rem;
        font-weight: 600;
      }
    }
    &-listen-all {
      margin: 0.3rem 0.8rem 0;
      width: 5.2rem;
      height: 0.66rem;
      font-size: 0.28rem;
      font-weight: 600;
      color: #ffffff;
      background: #fa3123;
      border-radius: 0.46rem;
      border: none;
      text-align: center;
    }
  }
</style>
